<template>
  <div id="app" class="app-container">
    <header class="header">
      <div class="container">
        <div class="header-content">
          <div class="header-top">
            <i class="fas fa-rings-wedding wedding-icon" @click="goToHome" style="color: #ffffff;"></i>
            <span class="home-button" @click="goToHome">Home</span>
          </div>
          <div class="header-divider"></div>
          <div class="header-bottom">
            <div class="logo">
              <h1 class="text-2xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight logo-text">
                <span class="text-white">Wed</span><span class="text-orange-400">lytic</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main class="main-content">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    goToHome() {
      this.$router.push('/');
    }
  }
}
</script>

<style>
body, html {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.app-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  padding: 0 1rem;
  width: 100%;
  box-sizing: border-box;
}

.container {
  max-width: 6xl;
  margin: 0 auto;
  padding: 0;
}

.header-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  padding-left: 45px;
  box-sizing: border-box;
}

.header-divider {
  width: 100%;
  border-bottom: 1px solid rgba(55, 65, 81, 1);
  margin: 10px 0;
}

.header-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0;
  padding-left: 45px;
  box-sizing: border-box;
}

.wedding-icon {
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.home-button {
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.home-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.logo {
  display: flex;
  align-items: center;
}

.logo-text {
  color: white;
  display: flex;
}

.text-white {
  color: #ffffff;
}

.text-orange-400 {
  color: #ff7e29;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px; /* Increase this value to ensure it is below the header */
}
</style>
