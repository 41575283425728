<template>
  <DefaultPageLayout>
    <div class="content">
      <h1>Weather Analysis</h1>
      <p class="description">
        Effortlessly analyze the weather forecast for your special day with our state-of-the-art forecasting tool.
        Get accurate and reliable weather predictions for your wedding venue and date, ensuring you can plan with confidence.
        No complex setups, just straightforward and user-friendly insights to keep your day perfect.
      </p>
      
      <div class="input-container">
        <label for="venue-address">1. Venue Address</label>
        <input id="venue-address" type="text" v-model="venueAddress" placeholder="Enter the venue address here">
      </div>
      
      <div class="input-container">
        <label for="wedding-date">2. Wedding Date</label>
        <input id="wedding-date" type="date" v-model="weddingDate">
      </div>
      
      <button class="generate-button" @click="generateAnalysis" :disabled="!isFormValid">
        Generate Analysis
      </button>
    </div>
  </DefaultPageLayout>
</template>

<script>
import DefaultPageLayout from './DefaultPageLayout.vue';

export default {
  name: 'ForecastAnalysis',
  components: {
    DefaultPageLayout,
  },
  data() {
    return {
      venueAddress: '',
      weddingDate: '',
      coordinates: null,
    };
  },
  computed: {
    isFormValid() {
      return this.venueAddress !== '' && this.weddingDate !== '';
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.initializeAutocomplete();
  },
  methods: {
    initializeAutocomplete() {
      const input = document.getElementById('venue-address');
      // eslint-disable-next-line no-undef
      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.setFields(['address_components', 'geometry']);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          // User did not select a valid place
          return;
        }
        this.venueAddress = this.getFormattedAddress(place.address_components);
        this.coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
      });
    },
    getFormattedAddress(addressComponents) {
      const components = {};
      addressComponents.forEach(component => {
        component.types.forEach(type => {
          components[type] = component.long_name;
        });
      });
      
      // Construct a formatted address
      const address = `${components.street_number || ''} ${components.route || ''}, ${components.locality || ''}, ${components.administrative_area_level_1 || ''} ${components.postal_code || ''}, ${components.country || ''}`;
      return address.trim();
    },
    generateAnalysis() {
      if (this.isFormValid) {
        // Replace with the route to your results page
        this.$router.push({
          path: '/weather-results',
          query: {
            address: this.venueAddress,
            date: this.weddingDate,
            lat: this.coordinates.lat,
            lng: this.coordinates.lng
          }
        });
      }
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.description {
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
  color: gray;
}

.input-container {
  margin-bottom: 1.5rem;
}

label {
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type="text"],
input[type="date"] {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.generate-button {
  background-color: #584ae7;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  margin-top: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.generate-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.generate-button:not(:disabled):hover {
  background-color: #7b68ee;
}
</style>
