<template>
    <div v-if="show" class="modal-overlay" @click.self="close">
      <div class="modal-container">
        <h2 class="text-2xl font-bold mb-4">Weather Data Details</h2>
        <table class="table-auto w-full">
          <thead>
            <tr>
              <th class="px-4 py-2">Date</th>
              <th class="px-4 py-2">High Temp (°F)</th>
              <th class="px-4 py-2">Low Temp (°F)</th>
              <th class="px-4 py-2">Rainfall (inches)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in weatherDetails" :key="index">
              <td class="border px-4 py-2">{{ data.date }}</td>
              <td class="border px-4 py-2">{{ data.highTemp }}</td>
              <td class="border px-4 py-2">{{ data.lowTemp }}</td>
              <td class="border px-4 py-2">{{ data.rainfall }}</td>
            </tr>
          </tbody>
        </table>
        <button class="mt-4 px-4 py-2 bg-blue-500 text-white rounded" @click="close">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WeatherDataModal',
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      weatherDetails: {
        type: Array,
        required: true,
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
  }
  .table-auto {
    width: 100%;
    border-collapse: collapse;
  }
  .table-auto th,
  .table-auto td {
    border: 1px solid #ccc;
    padding: 0.5rem;
    text-align: left;
  }
  .table-auto th {
    background-color: #f5f5f5;
  }
  </style>
  