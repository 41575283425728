<template>
  <div class="home">
    <div class="modules">
      <a class="module" href="/forecast-analysis">
        <i class="fas fa-temperature-half weather-icon"></i>
        <h2>Forecast Analysis</h2>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start */
  height: 100vh;
  width: 100%;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.modules {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: -5px; /* Adjust this value to position closer to the header */
  width: 100%;
  box-sizing: border-box;
}

.module {
  background-color: #ffffff;
  color: #333333;
  text-decoration: none;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
  transition: all 0.3s ease;
}

.module:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.weather-icon {
  font-size: 40px;
  color: #333333;
  margin-bottom: 10px;
}

.module h2 {
  font-size: 18px;
  margin: 0;
}
</style>
