<template>
    <div class="page-container">
      <div class="white-box">
        <button class="back-button" @click="goToHome">
          <i class="fas fa-circle-arrow-left back-icon"></i>
          Back to overview
        </button>
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "DefaultPageLayout",
    methods: {
      goToHome() {
        this.$router.push('/'); // Assumes you are using Vue Router
      }
    }
  };
  </script>
  
  <style scoped>
  .page-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px; /* Adjust based on the height of your header */
    width: 100%;
    box-sizing: border-box;
  }
  
  .white-box {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 85%;
    max-width: 1200px; /* Max width to prevent it from stretching too much on large screens */
    margin-top: -30px; /* Adjust this value to move the content below the header */
    margin-left: auto;
    margin-right: auto;
  }
  
  .back-button {
    background-color: #584ae7; /* Button background color */
    color: #ffffff; /* Button text color */
    padding: 0.5rem 1.5rem; /* Button padding */
    border: none; /* Remove default border */
    border-radius: 1.5rem; /* Rounded corners */
    font-size: 1rem; /* Font size */
    font-weight: bold; /* Font weight */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text */
    text-decoration: none; /* Remove underline */
    margin-bottom: 1rem; /* Space below button */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .back-button:hover {
    background-color: #7b68ee; /* Lighter shade on hover */
  }
  
  .back-icon {
    margin-right: 0.5rem; /* Space between icon and text */
    color: #ffffff; /* Icon color */
  }
  </style>