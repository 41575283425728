<template>
    <DefaultPageLayout>
      <div class="container mx-auto p-6">
        <h1 class="text-3xl font-bold mb-4">Weather Analysis Results</h1>
        <div v-if="weatherData" class="weather-data">
          <h2 class="text-xl font-semibold">{{ weatherData.address }}</h2>
          <p class="text-gray-500 mb-2">Date: {{ weatherData.date }}</p>
          <div class="weather-details grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="temperature p-4 border rounded shadow-sm cursor-pointer" @click="showModal = true">
              <h3 class="text-lg font-medium mb-2">Average Temperature</h3>
              <p class="text-2xl font-bold">{{ weatherData.avgTemp }} °F</p>
            </div>
            <div class="rainfall p-4 border rounded shadow-sm cursor-pointer" @click="showModal = true">
              <h3 class="text-lg font-medium mb-2">Average Rainfall</h3>
              <p class="text-2xl font-bold">{{ weatherData.avgRainfall }} inches</p>
            </div>
          </div>
          <p class="text-gray-500 mt-4">Elapsed Time: {{ elapsedTime }} seconds</p>
        </div>
        <div v-else class="loading-state">
          <p>Loading weather data...</p>
          <div class="progress-bar w-full bg-gray-200 rounded mt-2 h-4">
            <div class="progress h-full bg-green-500 rounded" :style="{ width: progress + '%' }"></div>
          </div>
          <p class="text-gray-500 mt-2">Elapsed Time: {{ elapsedTime }} seconds</p>
        </div>
        <WeatherDataModal :show="showModal" :weatherDetails="detailedWeatherData" @close="showModal = false" />
      </div>
    </DefaultPageLayout>
  </template>
  
  <script>
  import DefaultPageLayout from './DefaultPageLayout.vue';
  import WeatherDataModal from './WeatherDataModal.vue';
  import axios from 'axios';
  
  const CACHE_KEY = 'weatherDataCache';
  
  export default {
    name: 'WeatherResults',
    components: {
      DefaultPageLayout,
      WeatherDataModal,
    },
    data() {
      return {
        weatherData: null,
        detailedWeatherData: [],
        progress: 0,
        totalRequests: 0,
        completedRequests: 0,
        startTime: null,
        elapsedTime: 0,
        timer: null,
        cache: JSON.parse(localStorage.getItem(CACHE_KEY)) || {},
        showModal: false,
      };
    },
    created() {
      this.fetchWeatherData();
    },
    methods: {
      async fetchWeatherData() {
        const { address, date, lat, lng } = this.$route.query;
        const cacheKey = `${address}-${date}-${lat}-${lng}`;
  
        console.log('Fetching weather data for:', { address, date, lat, lng });
  
        if (this.cache[cacheKey]) {
          console.log('Using cached data for:', cacheKey);
          this.weatherData = this.cache[cacheKey];
          this.detailedWeatherData = this.cache[cacheKey].detailedData;
          return;
        }
  
        if (!address || !date || !lat || !lng) {
          console.error('Invalid query parameters:', { address, date, lat, lng });
          return;
        }
  
        const endDate = new Date(date);
        if (isNaN(endDate.getTime())) {
          console.error('Invalid date provided:', date);
          return;
        }
  
        const dateRanges = this.getDateRanges(endDate);
        this.totalRequests = dateRanges.length;
        this.startTime = Date.now();
        this.startTimer();
  
        try {
          const data = await this.fetchDataInBatches(dateRanges, lat, lng);
          console.log('Fetched data:', data);
          const weatherData = this.processWeatherData(data);
          console.log('Processed weather data:', weatherData);
          this.weatherData = {
            address,
            date,
            avgTemp: this.calculateAverageTemp(weatherData.detailedData),
            avgRainfall: this.calculateAverageRainfall(weatherData.detailedData),
            detailedData: weatherData.detailedData,
          };
  
          this.detailedWeatherData = weatherData.detailedData;
  
          this.cache[cacheKey] = this.weatherData;
          localStorage.setItem(CACHE_KEY, JSON.stringify(this.cache));
        } catch (error) {
          console.error('Error fetching weather data:', error);
          this.weatherData = {
            address,
            date,
            avgTemp: 'N/A',
            avgRainfall: 'N/A',
            detailedData: [],
          };
        } finally {
          this.stopTimer();
        }
      },
      getDateRanges(endDate) {
        const ranges = [];
        const currentYear = endDate.getFullYear();
  
        for (let year = currentYear - 10; year <= currentYear; year++) {
          const start = new Date(endDate);
          start.setFullYear(year);
          const end = new Date(start);
          end.setDate(start.getDate() + 1);
  
          ranges.push({
            start: start.toISOString().split('T')[0],
            end: end.toISOString().split('T')[0],
          });
        }
  
        console.log('Date ranges:', ranges);
        return ranges;
      },
      async fetchDataInBatches(ranges, lat, lng, batchSize = 3) {
        const data = [];
        const batches = Math.ceil(ranges.length / batchSize);
  
        for (let i = 0; i < batches; i++) {
          const batch = ranges.slice(i * batchSize, (i + 1) * batchSize);
          await Promise.all(batch.map(range => this.fetchDataForRange(range, lat, lng)
            .then(result => {
              if (result) {
                console.log(`Data for range ${range.start} to ${range.end}:`, result);
                data.push(...result.temperature_2m.map((temp, index) => ({
                  time: result.time[index],
                  temperature_2m: temp,
                  precipitation: result.precipitation ? result.precipitation[index] : 0,  // Add precipitation data if available
                })));
              }
            })
            .catch(error => console.error('Error in batch request:', error))
          ));
          this.updateProgress();
        }
  
        return data;
      },
      async fetchDataForRange(range, lat, lng, retries = 3, delay = 100) {
        try {
          console.log(`Fetching data for range: ${range.start} to ${range.end}`);
          const response = await axios.get('https://archive-api.open-meteo.com/v1/era5', {
            params: {
              latitude: parseFloat(lat),
              longitude: parseFloat(lng),
              start_date: range.start,
              end_date: range.start, // Ensure only one day is fetched
              hourly: 'temperature_2m,precipitation',
            },
          });
          console.log(`Received data for range: ${range.start} to ${range.end}`);
          return response.data.hourly;
        } catch (error) {
          console.error(`Failed to fetch data for range ${range.start} to ${range.end}`, error);
          if (retries > 0 && error.response && error.response.status >= 500) {
            console.warn(`Retrying request for range ${range.start} to ${range.end} (${retries} retries left)`);
            await this.delay(delay);
            return this.fetchDataForRange(range, lat, lng, retries - 1, delay * 2);
          } else {
            throw error;
          }
        }
      },
      processWeatherData(data) {
        const detailedData = [];
        const tempByDate = {};
  
        data.forEach(record => {
          const date = record.time.split('T')[0];
          const temp = record.temperature_2m;
          const rainfall = record.precipitation;
  
          if (!tempByDate[date]) {
            tempByDate[date] = {
              date,
              highTemp: temp,
              lowTemp: temp,
              totalRainfall: rainfall,
            };
          } else {
            tempByDate[date].highTemp = Math.max(tempByDate[date].highTemp, temp);
            tempByDate[date].lowTemp = Math.min(tempByDate[date].lowTemp, temp);
            tempByDate[date].totalRainfall += rainfall;
          }
        });
  
        Object.values(tempByDate).forEach(day => {
          detailedData.push({
            date: day.date,
            highTemp: this.convertToFahrenheit(day.highTemp),
            lowTemp: this.convertToFahrenheit(day.lowTemp),
            rainfall: this.convertToInches(day.totalRainfall),
          });
        });
  
        // Sort detailedData by date before returning
        detailedData.sort((a, b) => new Date(a.date) - new Date(b.date));
  
        return {
          detailedData,
        };
      },
      calculateAverageTemp(detailedData) {
        let totalHighTemp = 0;
        let totalLowTemp = 0;
        let highTempCount = 0;
        let lowTempCount = 0;
  
        detailedData.forEach(data => {
          if (data.highTemp !== 'N/A') {
            totalHighTemp += parseFloat(data.highTemp);
            highTempCount++;
          }
          if (data.lowTemp !== 'N/A') {
            totalLowTemp += parseFloat(data.lowTemp);
            lowTempCount++;
          }
        });
  
        const avgHighTemp = highTempCount > 0 ? totalHighTemp / highTempCount : 0;
        const avgLowTemp = lowTempCount > 0 ? totalLowTemp / lowTempCount : 0;
  
        return ((avgHighTemp + avgLowTemp) / 2).toFixed(2);
      },
      calculateAverageRainfall(detailedData) {
        let totalRainfall = 0;
        let rainfallCount = 0;
  
        detailedData.forEach(data => {
          if (data.rainfall !== 'N/A') {
            totalRainfall += parseFloat(data.rainfall);
            rainfallCount++;
          }
        });
  
        return rainfallCount > 0 ? (totalRainfall / rainfallCount).toFixed(2) : 'N/A';
      },
      convertToFahrenheit(celsius) {
        return ((celsius * 9) / 5 + 32).toFixed(2);
      },
      convertToInches(mm) {
        return (mm / 25.4).toFixed(2);
      },
      updateProgress() {
        this.completedRequests++;
        this.progress = ((this.completedRequests / this.totalRequests) * 100).toFixed(2);
      },
      startTimer() {
        this.timer = setInterval(() => {
          this.elapsedTime = ((Date.now() - this.startTime) / 1000).toFixed(2);
        }, 100);
      },
      stopTimer() {
        clearInterval(this.timer);
        this.elapsedTime = ((Date.now() - this.startTime) / 1000).toFixed(2);
      },
      delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .weather-details {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .weather-details div {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: box-shadow 0.3s ease;
  }
  
  .weather-details div:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .temperature h3,
  .rainfall h3 {
    margin-bottom: 0.5rem;
  }
  
  .temperature p,
  .rainfall p {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .progress-bar {
    width: 100%;
    background-color: #e2e8f0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .progress {
    height: 1rem;
    background-color: #48bb78;
    transition: width 0.5s ease-in-out;
  }
  
  .elapsed-time {
    margin-top: 1rem;
    font-size: 1rem;
    color: gray;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  