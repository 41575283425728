import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home.vue';
import ForecastAnalysis from '@/components/ForecastAnalysis.vue';
import WeatherResults from '@/components/WeatherResults.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/forecast-analysis',
    name: 'ForecastAnalysis',
    component: ForecastAnalysis
  },
  {
    path: '/weather-results',
    name: 'WeatherResults',
    component: WeatherResults
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
